import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import logo from '../../content/assets/logo.svg'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ThemeButton from './ThemeButton'
import TwitterButton from './TwitterButton'
import InstagramButton from "./InstagramButton"
import { HamburgerSqueeze } from 'react-animated-burgers'

const Hamburger = styled(HamburgerSqueeze)`
  &:focus {
    outline:none!important
  }
`

class Navigation extends React.Component {
  state = {
    isActive: false,
    collapsed: true
  }

  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive,
      collapsed: !this.state.collapsed
    })
  }
  
  render() {
  const { location } = this.props
  // const metaDescription = description || site.siteMetadata.description
  return (
        <Navbar
          sticky="top"
          style={{
          backgroundColor: 'var(--bg)',
          color: 'var(--textNormal)',
          borderBottom: '1px solid var(--hr)',
          transition: 'color 0.2s linear, background 0.2s linear',
          }}
          expand="lg"
          expanded={!this.state.collapsed}
          width="100%"
          height="100%"
          className={"mr-auto navbar-default"}
          >
            <Link to='/'>
              <Navbar.Brand>
                <img
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="Wolf on Moon"
                  rel="preload"
                  loading="eager"
                />
                {'   '}
                Wolf on Moon
            </Navbar.Brand>
          </Link>
            <Hamburger aria-controls="navbar-nav" className="p-0 navbar-toggler collapsed" collapsed={this.state.isActive} isActive={this.state.isActive} toggleButton={this.toggleButton} buttonColor='var(--bg)' barColor='var(--primary)' />
          <Navbar.Collapse id="navbar-nav" className="">
            <hr className="hidden-lg-up" />
            <Nav justify fill as="ul" style={{
              color: 'var(--textNormal)',
              }}>
              <Nav.Item as="li" className={
                location.pathname === '/'  || location.pathname === '/Home/' ? 'nav-item active' : 'nav-item'
              }>
                <Link to="/">Home</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/clients') ? 'active' : ''
              }>
                <Link to="/clients/" eventkey="clients">Clients</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/projects') ? 'active' : ''
              }>
                <Link to="/projects/" eventkey="projects">Projects</Link>
              </Nav.Item>
              {/* <Nav.Item as="li" className={
                location.pathname.includes('/blog') ? 'active' : ''
              }>
                <Link to="/blog/" eventkey="blog">Blog</Link>
              </Nav.Item> */}
              <Nav.Item as='li' className='p-0'>
                <NavDropdown title="Services" id="nav-dropdown" className='text-center'>
                  <NavDropdown.Item
                    className={
                    location.pathname.includes('/web-development') ? 'active' : ''
                  }
                    eventKey="web-development">
                    <Link to="/web-development/" eventkey="web-development">Web Development</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={
                    location.pathname.includes('/consultation') ? 'active' : ''
                  }
                    eventKey="consultation">
                    <Link to="/consultation/" eventkey="consultation">Consultation</Link>
                  </NavDropdown.Item>
                </NavDropdown>
            </Nav.Item>
            </Nav>
            <hr className="hidden-lg-up" />
            <Nav
              justify
              fill
              as="ul"
              className="ml-auto nav-right"
              style={{
                color: 'var(--textNormal)',
                }}>
              <Nav.Item as="li">
                <TwitterButton />
              </Nav.Item>
              <Nav.Item as="li">
                <InstagramButton />
              </Nav.Item>
              <Nav.Item as="li">
                  <ThemeButton/>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
  )
  }
}

export default Navigation