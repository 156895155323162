import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import useSound from 'use-sound'
import clickSfx from '../../static/sounds/tweet.mp3';



const TwitterButton = () => {
  const [play] = useSound(clickSfx)
  const [ishovering, setIsHovering] = React.useState(
    false
  );

  return (
        <a
        onClick={play}
        href="https://twitter.com/wlfnmn"
        target="_blank"
        alt="Twitter"
        rel="noopener noreferrer"
        ishovering={ishovering ? ishovering : undefined}
        >
          <FontAwesomeIcon icon={faTwitter} />{' '}
          Twitter
        </a>
      )}


export default TwitterButton



