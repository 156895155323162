import React from "react"
import styled from "styled-components"
import Hero from "./hero"

const BackgroundSection = () => {

      return (
        <div className='outer-space-container'>
          <div className='space-container'>
            <div className='stars'></div>
            <div className='twinkling'></div>
            <div className='clouds'></div>
          </div>
          <div className="position-relative pt-5" style={{zIndex: '99'}}>
            <Hero title="Wolf on Moon" />
          </div>
        </div>
      )
    }

const HomeBanner = styled(BackgroundSection)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`

export default HomeBanner
