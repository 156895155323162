import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import Hero from "./hero"

const BackgroundSection = ({ className, title, image, description }) => (
  <StaticQuery
    query={graphql`
      query {
        clients: file(relativePath: { eq: "space-bg4.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        projects: file(relativePath: { eq: "space-bg7.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        blog: file(relativePath: { eq: "space-bg6.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        about: file(relativePath: { eq: "about.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        web_Development: file(relativePath: { eq: "space-bg1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        consultation: file(relativePath: { eq: "space-bg1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
      }
    `}
    render={data => {
      // Set ImageData.
      const imageName = image.toString()
      const imageData = data[imageName].childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <Hero title={title} text={description} />
        </BackgroundImage>
      )
    }}
  />
)

const Banner = styled(BackgroundSection)`
  width: 100%;
  background-position: top center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default Banner
