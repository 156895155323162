import React from "react"
import { Jumbotron } from "react-bootstrap"

class Hero extends React.Component {
  render() {


  return (
        <Jumbotron fluid className="jumbotron-background-image text-center">
              <div className="p-5">
                <h1 style={{color: 'var(--textTitle)'}}>{this.props.title}</h1>
                <p>
                  {this.props.text}
                </p>
              </div>
      </Jumbotron>
  )
}
}

export default Hero