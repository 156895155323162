import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import useSound from 'use-sound'
import clickSfx from '../../static/sounds/photo.mp3';



const InstagramButton = () => {
  const [play] = useSound(clickSfx)

  return (
        <a
        onClick={play}
        href="https://instagram.com/wolfonmoon"
        target="_blank"
        alt="Instagram"
        rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />{' '}
          Instagram
        </a>
      )}


export default InstagramButton



