import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "./navigation"
import { Container } from "react-bootstrap"
import Footer from "./footer"
import HomeBanner from "./homepage/banner"
import Banner from "./banner"
// import HomeCarousel from './homepage/carousel'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === '/'){location.pathname = "/Home/"}
    let name = location.pathname.replace(/\\|\//g,'')
    
    if (name === 'web-development') {
        name = 'web_Development'
    }
    let nameCapitalized = name.replace(/_/g, ' ');
    nameCapitalized = nameCapitalized.charAt(0).toUpperCase() + nameCapitalized.slice(1)

    // Display the homepage banner component if the path is '/'.
    if (location.pathname === rootPath || location.pathname === "/Home/") {
      header = (
        <HomeBanner>
        </HomeBanner>
      )
    }
    // Display the normal banner component if the path is one of the main pages.
    else if (
      location.pathname === ('/about') || location.pathname === ('/about/') ||
      location.pathname === ('/clients') || location.pathname === ('/clients/') ||
      location.pathname === ('/projects') || location.pathname === ('/projects/') ||
      location.pathname === ('/blog') || location.pathname === ('/blog/') ||
      location.pathname === ('/list') || location.pathname === ('/list/') ||
      location.pathname === ('/web-development') || location.pathname === ('/web-development/') ||
      location.pathname === ('/consultation') || location.pathname === ('/consultation/')
      )
      {
      // The name variable is the pathname without any slashes.
      header = (
        <div>
        <Banner title={nameCapitalized} image={name} />
        </div>
      )
    }
    else {
      // The header is an empty div if the user is not on ont of the main pages.
      header = (
        <div>
        </div>
      )
    }

    return (
      <div style={{
        backgroundColor: 'var(--bg-body)',
        color: 'var(--textNormal)',
      }}>
      <Navigation {...this.props} />
        {header}
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
