import * as React from "react";
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon,  } from '@fortawesome/free-solid-svg-icons'
import useSound from 'use-sound'
import clickSfx from '../../static/sounds/ui.mp3';
import styled from 'styled-components'

const AnimatedIcon = styled(FontAwesomeIcon)`
-webkit-transition: all 2s ease;  
-moz-transition: all 2s ease;  
-o-transition: all 2s ease;  
-ms-transition: all 2s ease;  
transition: all 2s ease;
}
`


const ThemeButton = () => {
  const [play] = useSound(clickSfx)

  return (
    <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <label>
            <input
              type="checkbox"
              onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
              checked={theme === 'dark'}
              onClick={play}
            />
            {' '}
            {theme === 'dark' ? <AnimatedIcon icon={faMoon} /> : <AnimatedIcon icon={faSun} />}
            {' '}
            {theme === 'dark' ? "Dark" : "Light"}
          </label>
          )}
          </ThemeToggler>
      )}


export default ThemeButton