import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Navbar } from "react-bootstrap"
import logo from '../../content/assets/logo.svg'
import Wolf from '../../content/assets/wolf.svg'

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer
          style={{
            background: 'var(--bg-card)',
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out',
            zIndex: '30',
            position: 'relative',
          }}
        >
          <Container className='footer' fluid>
            <Row className="p-4">
              <Col lg={2} md={12} sm={12} xs={12} className='text-md-left text-sm-center text-xs-center pb-3'>
                <h4>Wolf on Moon</h4>
                <p>
                  A digital agency that provides web design and development and digital consultancy as a service to clients across the world.
                </p>
                <br />
              </Col>
              <hr className='d-xl-none d-lg-none d-md-block d-sm-block d-xs-block' />
              <Col lg={2} md={3} sm={6} xs={6}>
                <ul className="list-unstyled text-md-right text-sm-left text-xs-left ml-0 mb-5">
                  <li><h5>Currency</h5></li>
                  <li><a href="https://www.bankofengland.co.uk/" alt='Pound Sterling'>💷 Pound Sterling (GBP)</a></li>
                  <li><a href="https://bitcoin.org"><img src='https://s2.coinmarketcap.com/static/img/coins/32x32/1.png' alt='Bitcoin' /> Bitcoin (BTC) </a></li>
                  <li><a href="https://ethereum.org"><img src='https://s2.coinmarketcap.com/static/img/coins/32x32/1027.png' alt='Ethereum' /> Ethereum (ETH)</a></li>
                  <li><a href="https://cardano.org"><img src='https://s2.coinmarketcap.com/static/img/coins/32x32/2010.png' alt='Cardano' /> Cardano (ADA) </a></li>
                </ul>
              </Col>
              <Col lg={2} md={3} sm={6} xs={6}>
                <ul className="list-unstyled text-md-right text-sm-left text-xs-left ml-0 mb-2">
                  <li><h5>Vision</h5></li>
                  <li><a href="https://jamstack.org/">Jamstack</a></li>
                  <li><a href="https://en.wikipedia.org/wiki/KISS_principle">KISS</a></li>
                  <li><a href="https://www.nngroup.com/articles/theory-user-delight/">Delight</a></li>
                  <li><a href="https://bitcoin.org/bitcoin.pdf">Bitcoin</a></li>
                </ul>
              </Col>
              <Col lg={2} md={3} sm={6} xs={6}>
                <ul className="list-unstyled text-md-right text-sm-left text-xs-left ml-0 mb-2">
                  <li><h5>Technology</h5></li>
                  <li><a href="https://gatsbyjs.org/">Gatsby</a></li>
                  <li><a href="https://reactjs.org">React</a></li>
                  <li><a href="https://www.netlify.com">Netlify</a></li>
                  <li><a href="https://zapier.com">Zapier</a></li>
                </ul>
              </Col>
              <Col lg={2} md={3} sm={6} xs={6}>
                <ul className="list-unstyled text-md-right text-sm-left text-xs-left ml-0 mb-2">
                  <li><h5>Approach</h5></li>
                  <li><a href="https://uxchecklist.github.io/">UX Checklist</a></li>
                  <li><a href="https://www.websitebuilderexpert.com/designing-websites/why-you-should-not-hire-a-website-designer/">Website?</a></li>
                  <li><a href="https://teamtreehouse.com">Treehouse</a></li>
                  <li><a href="https://www.pixelkicks.co.uk/blog/how-to-write-website-brief/">Write a brief</a></li>
                </ul>
              </Col>
              <Col lg={2} md={3} sm={6} xs={12}>
                <ul className="list-unstyled text-md-right text-sm-center text-xs-center ml-0">
                  <li><h5>Recognition</h5></li>
                  <li><Link to="/attributions">Attributions</Link></li>
                </ul>
              </Col>
            </Row>
          </Container>
          <Container className='bottom-footer' fluid>
            <Row>
              <Col md={6} sm={12} xs={12} className="d-inline-block pb-3 text-md-left text-sm-center text-xs-center">
                <Navbar.Brand href="/">
                  <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Wolf on Moon"
                  />
                  {'  '}
                  Wolf on Moon
                </Navbar.Brand>
                © {new Date().getFullYear()}
              </Col>
              <Col md={6} sm={12} xs={12} className="d-inline-block pr-4 pt-2 pb-3 text-md-right text-sm-center text-xs-center">
                <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
                  <em>Don't trip </em>😉
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    )
  }
}

export default Footer