import * as React from "react";
import { Jumbotron, Container, Row, Col } from "react-bootstrap"
import { useDencrypt } from "use-dencrypt-effect";
import Link from 'gatsby-link'
import logo from '../../../content/assets/logo.svg'


const Hero = () => {
  const { result, dencrypt } = useDencrypt();

  const values = ["We build fast websites", "We help businesses go digital", "We work on our own projects", "We focus on solving problems", "We can improve your website", "Please explore for more info", "This message now repeats"];

  React.useEffect(() => {
    let i = 0;

    const action = setInterval(() => {
      dencrypt(values[i]);

      i = i === values.length - 1 ? 0 : i + 1;
    }, 6000);

    return () => clearInterval(action);
  }, []);

  return (
    <Jumbotron fluid className="homepage-hero jumbotron-background-image text-center" style={{ zIndex: 99, marginBottom: 0 }}>
      <div className="p-5">
        <img className='hero-logo' height='100px' src={logo} />
        <div className="pt-5 pb-5 position-relative fixed-height-100">
          <h1 className="pb-5 pt-1" style={{ color: 'var(--textTitle)' }}>{result ? result : 'Wolf on Moon'}</h1>
        </div>
        <Container fluid>
          <Row>
            <Col md={{ span: 4, offset: 2 }} sm={{ span: 6, offset: 0 }} xs={12} className='mb-2'>
              <Link className="btn btn-sm animated-button thar-one" to="/web-development" target="_blank" rel="noopener noreferrer" role="button">Web Development</Link>
            </Col>
            {'  '}
            <Col md={4} sm={6} xs={12} className='mb-2'>
              <Link className="btn btn-sm animated-button thar-two" to="/consultation/" role="button">Consultation</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Jumbotron>
  );
};


export default Hero